<!-- 主观题 -->
<template>
	<div class="subjectiveWork-page">
		<div class="nav-content">
			<div class="nav-title flex-ac">
				<div class="back flex-jc-ac" @click="back">&lt; | 返回</div>
				<div class="chapte-title">{{ title }}</div>
			</div>
		</div>
		<div class="work-container">
			<div class="work-content flex-jb">
				<div class="left">
					<div class="problems">
						<div class="title">问答题</div>
						<div class="content" v-html="workInfo.tminfo"></div>
					</div>
				</div>
				<div class="right" v-if="workInfo">
					<!-- 个人 -->
					<div class="personal-container" v-if="workInfo.usertype == 2">
						<!-- 未提交/修改重新重新提交：显示 编辑内容容器 -->
						<div class="editor-container" v-if="workInfo.user_zy_type == 2 || showEditorContainer">
							<div class="user flex-ac">
								<img src="https://static.mudu.tv/index/avatar.png" alt="" class="avatar" />
								<div class="name">{{ userInfo.s_name }}</div>
							</div>
							<!-- 富文本编辑框 -->
							<div class="richText-contianer">
								<textarea v-model.trim="content" placeholder="最多输入100个字" maxlength="100"></textarea>
								<div class="txt-count">{{ content.length }}/ 100</div>
								<!-- <quillEditor ref="quillEditorRef" v-model.trim="content" :options="editorOptions"></quillEditor> -->
							</div>
							<!-- 上传文件容器 -->
							<div class="add-title">上传附件<span>(可上传文件类型: pdf、mp4)</span></div>
							<div class="uploadfile-container flex">
								<div class="input-wrap" v-if="uploadFileList.length < 5">
									<div class="add-file-box">
										<input type="file" @change="upload" />
										<img src="../assets/image/icon-add.png" alt="" class="add" />
									</div>
								</div>
								<div class="input-wrap" v-for="(item, index) in uploadFileList" :key="index">
									<div class="upload-file-box">
										<img :src="item.fielicon" alt="" class="file" />
										<div class="delete flex-jc-ac" @click="deleteUploadFile(index)"><img src="../assets/image/icon-close.png" alt="" /></div>
										<div class="change"><input type="file" @change="upload($event, index)" />修改</div>
									</div>
								</div>
							</div>
							<div class="submit-btn" @click="submit">提交</div>
						</div>
						<div class="personal-answer-list" v-else>
							<div class="user-answer">
								<div class="user-wrap flex-jb-ac">
									<div class="user flex-ac">
										<img src="https://static.mudu.tv/index/avatar.png" alt="" class="avatar" />
										<div class="name">{{ userInfo.s_name }}</div>
									</div>
									<div class="time" v-if="workInfo.zylist">{{ workInfo.zylist.hutime }}</div>
								</div>
								<div class="content-wrap" v-if="workInfo.zylist">
									<div class="content">{{ workInfo.zylist.hutext }}</div>
									<img v-for="(item, index) in workInfo.zylist.wjrullist" :key="index" :src="item.fielicon" alt="" class="file-img" />
									<div class="modify-work" @click="modifyWork" v-if="workInfo.user_dt_type == 1 &&workInfo.isuserpingfen == 2">{{ workInfo.user_dt_text || "修改" }}</div>
								</div>
							</div>
						</div>
						<div class="teacher-appraise" v-if="workInfo.user_hf_type == 2 && workInfo.py">
							<div class="h2">老师评语回复</div>
							<div class="user-wrap flex-jb-ac">
								<div class="user flex-ac">
									<img :src="workInfo.py.hfimg" alt="" class="avatar" />
									<div class="name">{{ workInfo.py.hfname }}</div>
								</div>
								<div class="time">{{ workInfo.py.hftime }}</div>
							</div>
							<div class="content-wrap">
								<div class="content">{{ workInfo.py.hftext }}</div>
								<div class="rate-wrap flex-ac">
									<span>得分</span>
									<span style="color: #ffffff;margin-left: 5px">{{workInfo.py.pfnum}}</span>
									<!-- <span>作业完成度</span>
									<el-rate disabled v-model="workInfo.py.fs"></el-rate> -->
								</div>
							</div>
						</div>
					</div>

					<div class="group-container" v-else>
						<!-- <div class="publish-work-btn" v-if="workInfo.grinfo && workInfo.grinfo.user_zy_type == 2 && !showEditorContainer" @click="showEditorContainer = true">发布作业</div> -->
						<!-- 未提交/修改重新重新提交：显示 编辑内容容器 -->
						<div class="group-personal-container" v-if="workInfo.grinfo">
							<div class="h1">{{ workInfo.zuname }}</div>
							<div class="editor-container" v-if="workInfo.grinfo.user_zy_type == 2 || showEditorContainer">
								<div class="user flex-ac">
									<img src="https://static.mudu.tv/index/avatar.png" alt="" class="avatar" />
									<div class="name">{{ userInfo.s_name }}</div>
								</div>
								<!-- 富文本编辑框 -->
								<div class="richText-contianer">
									<textarea v-model.trim="content" placeholder="最多输入100个字" maxlength="100"></textarea>
									<div class="txt-count">{{ content.length }}/ 100</div>
								</div>
								<!-- 上传文件容器 -->
								<div class="add-title">上传附件<span>(可上传文件类型: doc、excel、ppt、pdf、png、jpg、jpeg)</span></div>
								<div class="uploadfile-container flex">
									<div class="input-wrap" v-if="uploadFileList.length < 5">
										<div class="add-file-box">
											<input type="file" @change="upload" />
											<img src="../assets/image/icon-add.png" alt="" class="add" />
										</div>
									</div>
									<div class="input-wrap" v-for="(item, index) in uploadFileList" :key="index">
										<div class="upload-file-box">
											<img :src="item.fielicon" alt="" class="file" />
											<div class="delete flex-jc-ac" @click="deleteUploadFile(index)"><img src="../assets/image/icon-close.png" alt="" /></div>
										</div>
									</div>
								</div>
								<div class="submit-btn" @click="submit">提交</div>
							</div>
							<div class="user-answer" v-if="workInfo.grinfo.user_zy_type == 1 && !showEditorContainer">
								<div class="user-wrap flex-jb-ac">
									<div class="user flex-ac">
										<img src="https://static.mudu.tv/index/avatar.png" alt="" class="avatar" />
										<div class="name">{{ userInfo.s_name }}</div>
									</div>
									<div class="time" v-if="workInfo.grinfo.zylist">{{ workInfo.grinfo.zylist.hutime }}</div>
								</div>
								<div class="content-wrap" v-if="workInfo.grinfo.zylist">
									<div class="content">{{ workInfo.grinfo.zylist.hutext }}</div>
									<img v-for="(item, index) in workInfo.grinfo.zylist.wjrullist" :key="index" :src="item.fielicon" alt="" class="file-img" />
									<div class="modify-work" @click="modifyWork" v-if="workInfo.grinfo.user_dt_type == 1 && workInfo.grinfo.isuserpingfen == 2">{{ workInfo.grinfo.user_dt_text || "修改" }}</div>
								</div>
							</div>
							<div class="teacher-appraise" v-if="workInfo.grinfo.user_hf_type == 2 && workInfo.grinfo.py">
								<div class="h2">老师评语回复</div>
								<div class="user-wrap flex-jb-ac">
									<div class="user flex-ac">
										<img :src="workInfo.grinfo.py.hfimg" alt="" class="avatar" />
										<div class="name">{{ workInfo.grinfo.py.hfname }}</div>
									</div>
									<div class="time">{{ workInfo.grinfo.py.hftime }}</div>
								</div>
								<div class="content-wrap">
									<div class="content">{{ workInfo.grinfo.py.hftext }}</div>
									<div class="rate-wrap flex-ac">
										<span>得分</span>
										<span style="color: #ffffff;margin-left: 5px">{{workInfo.grinfo.py.pfnum}}</span>
										<!-- <span>作业完成度</span>
										<el-rate disabled v-model="workInfo.grinfo.py.fs"></el-rate> -->
									</div>
								</div>
							</div>
						</div>

						<div class="group-list" v-if="workInfo.zulist && workInfo.zulist.length > 0">
							<div class="group-user-item" v-for="(item, index) in workInfo.zulist" :key="index">
								<div class="info flex-jb-ac">
									<div class="name">{{ item.s_name }}</div>
									<div class="time" v-if="item.user_zy_type == 1 && item.zylist">{{ item.zylist.hutime }}</div>
								</div>
								<div class="content" v-if="item.user_zy_type == 1">{{ item.zylist.hutext }}</div>
								<div class="content no-submit" v-else>未发布</div>
								<img v-for="(item, i) in item.zylist.wjrullist" :key="i" :src="item.fielicon" alt="" class="file-img" />
							</div>
						</div>
						<div class="group-nodata" v-else>{{ workInfo.zulist }}暂无小组其他成员提交</div>
						<div class="overall-evaluation-container" v-if="workInfo.zuhflist && workInfo.zutype == 2">
							<div class="title">老师小组评语回复:</div>
							<div class="user-wrap flex-jb-ac">
								<div class="user flex-ac">
									<img :src="workInfo.zuhflist.zuhfimg" alt="" class="avatar" />
									<div class="name">{{ workInfo.zuhflist.zuhfname }}</div>
								</div>
								<div class="time">{{ workInfo.zuhflist.zuhftime }}</div>
							</div>
							<div class="content">{{ workInfo.zuhflist.zuhftext }}</div>
							<div class="rate-wrap flex-ac">
								<span>得分</span>
								<span style="color: #ffffff;margin-left: 5px">{{workInfo.zuhflist.pfnum}}</span>
								<!-- <span>作业完成度</span>
								<el-rate disabled v-model="workInfo.zuhflist.zufs"></el-rate> -->
							</div>
						</div>
					</div>

					<div class="back" @click="back">返回</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "../assets/css/editor.css";
// import { quillEditor } from "vue-quill-editor";
// import fileDoc from "../assets/image/fileType/file-doc.png";
// import fileExcel from "../assets/image/fileType/file-excel.png";
// import fileImg from "../assets/image/fileType/file-img.png";
// import filePdf from "../assets/image/fileType/file-pdf.png";
// import filePpt from "../assets/image/fileType/file-ppt.png";
// import fileTxt from "../assets/image/fileType/file-txt.png";
// import fileZip from "../assets/image/fileType/file-zip.png";

import { $getSubjectiveWork, $uploadFile, $submitSubjectiveWork } from "../services/course";
export default {
	name: "subjectiveWork",
	data() {
		return {
			courseId: "",
			lessonId: "",
			title: "", // 课时标题

			showEditorContainer: false,
			content: "", //富文本编辑框内容
			uploadFileLoading: false, // 是否正在上传文件中
			uploadFileList: [],

			submitLoading: false, // 是否正在提交作业中

			hasSubmit: true,
			workInfo: {},
			showReEditor: false,
			// 富文本编辑框配置选项
			editorOptions: {
				modules: {
					toolbar: [
						["bold", "italic", "underline", "strike"],
						[{ list: "ordered" }, { list: "bullet" }],
						[{ script: "sub" }, { script: "super" }], // superscript/subscript
						[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
						[{ size: ["small", false, "large", "huge"] }], // custom dropdown
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						[{ color: [] }], // dropdown with defaults from theme
						[{ font: [] }],
						[{ align: [] }],
						["clean"],
					],
				},
			},
			test1: 3,
		};
	},
	components: {
		// quillEditor,
	},

	computed: {
		userInfo() {
			return this.$store.getters.useInfo;
		},
	},

	created() {
		this.title = this.$route.query.title;
		this.lessonId = this.$route.query.id;
		this.courseId = this.$route.query.kcid;
		this.getWorkList();
	},

	methods: {
		async getWorkList() {
			const { data } = await $getSubjectiveWork({ ksid: this.lessonId, kcid: this.courseId });
			this.workInfo = data;
		},
		back() {
			this.$router.back();
		},
		async upload(e) {
			const file = e.target.files[0];
			if (file.size > this.workInfo.wjsize * 1024 * 1024) {
				this.$message.error("文件大小不能超过" + this.workInfo.wjsize + "MB");
				return;
			}
			if (this.uploadFileLoading) {
				this.$message.error("正在上传中，请稍后再试");
				return;
			}
			try {
				this.uploadFileLoading = true;
				let req = new FormData();
				req.append("file", file);
				req.append("type", 1);
				const { data } = await $uploadFile(req);
				this.uploadFileLoading = false;
				this.uploadFileList.push(data);
			} catch (error) {
				this.uploadFileLoading = false;
			}
		},
		deleteUploadFile(index) {
			this.uploadFileList.splice(index, 1);
		},
		async submit() {
			if (this.content == "") {
				this.$message.error("提交文本内容不能为空");
				return;
			}
			if (this.uploadFileList.length > 5) {
				this.$message.error("最多只允许上传5个文件");
				return;
			}
			if (this.submitLoading) {
				this.$message.info("正在提交中，请稍后");
				return;
			}
			this.submitLoading = true;
			let req = new FormData();
			req.append("ksid", this.lessonId);
			req.append("ydtext", this.content);
			req.append("gpid", this.workInfo.gpid);
			req.append("sid", this.userInfo.id);
			this.uploadFileList.forEach((item, index) => {
				req.append(`wjurl[${item.fullurl}]`, item.fielicon);
			});
			try {
				const data = await $submitSubjectiveWork(req);
				this.submitLoading = false;
				if (data.code == 1) {
					this.$message.success("提交成功");
					this.showEditorContainer = false;
					this.content = "";
					this.uploadFileList = [];
					this.getWorkList();
				}
			} catch (error) {
				this.submitLoading = false;
			}
		},
		modifyWork() {
			this.showEditorContainer = true;
			if (this.workInfo.usertype == 2) {
				this.content = this.workInfo.zylist?.hutext;
				this.uploadFileList = this.workInfo.zylist?.wjrullist;
				// this.workInfo.zylist?.wjrullist.forEach(item => {
				// 	this.uploadFileList.push({ fullurl: item });
				// });
			} else {
				this.content = this.workInfo?.grinfo?.zylist?.hutext;
				this.uploadFileList = this.workInfo?.grinfo?.zylist?.wjrullist;
				// this.workInfo?.grinfo.zylist?.wjrullist.forEach(item => {
				// 	this.uploadFileList.push({ fullurl: item });
				// });
			}
		},
	},
};
</script>

<style scoped lang="less">
.nav-content {
	height: 52px;

	background: #f3f5f6;
	.nav-title {
		width: 1200px;
		height: 52px;
		margin: 0 auto;
		font-size: 16px;
		color: #1c1f21;
		.back {
			width: 120px;
			height: 37px;
			border-radius: 20px;
			color: #fff;
			margin-right: 20px;
			background-color: rgba(153, 153, 153, 1);
			cursor: pointer;
		}
	}
}
.work-container {
	background: #4d555d;
	min-height: 986px;
	padding-top: 50px;
	padding-bottom: 86px;
	.work-content {
		width: 1200px;
		margin: 0 auto;
		.left {
			width: 600px;
			padding-top: 54px;
			.problems {
				padding-left: 40px;
				padding-right: 60px;
				.title {
					font-size: 36px;
					color: #f3f5f6;
					margin-bottom: 30px;
				}
				.content {
					font-size: 18px;
					color: #ffffff;
					line-height: 36px;
					/deep/ img {
						max-width: 500px;
					}
				}
			}
		}
		.right {
			width: 600px;
			height: 850px;
			padding: 30px 40px;
			background: #222628;
			overflow-y: scroll;
			.editor-container {
				.user {
					font-size: 16px;
					color: #f3f5f6;
					font-weight: bold;
					margin-bottom: 20px;
					.avatar {
						width: 42px;
						height: 42px;
						border-radius: 50%;
						margin-right: 20px;
					}
				}
				.richText-contianer {
					// background-color: #fff;
					// .quill-editor {
					// 	/deep/ .ql-container {
					// 		height: 200px;
					// 	}
					// }
					textarea {
						width: 510px;
						height: 130px;
						font-size: 14px;
						line-height: 20px;
						resize: none;
						outline: none;
						padding: 20px;
						box-sizing: border-box;
						border-radius: 12px;
					}
					.txt-count {
						color: #fff;
						text-align: right;
					}
				}
				.add-title {
					margin-top: 30px;
					margin-bottom: 10px;
					color: #fff;
					span {
						font-size: 11px;
						margin-left: 10px;
					}
				}

				.uploadfile-container {
					margin-top: 20px;
					.input-wrap {
						width: 80px;
						height: 120px;
						margin-right: 20px;
						.add-file-box {
							width: 80px;
							height: 80px;
							background-color: rgba(251, 253, 255);
							overflow: hidden;
							border-radius: 8px;
							cursor: pointer;
							position: relative;
							input {
								position: relative;
								width: 80px;
								height: 80px;
								opacity: 0;
								cursor: pointer;
								z-index: 9;
							}
							.add {
								position: absolute;
								left: 50%;
								top: 50%;
								transform: translate(-50%, -50%);
								width: 30px;
							}
						}
						.upload-file-box {
							width: 100%;
							height: 100%;
							position: relative;
							.file {
								width: 80px;
								height: 80px;
								border-radius: 8px;
							}
							.delete {
								position: absolute;
								right: -15px;
								top: -15px;
								width: 40px;
								height: 40px;
								cursor: pointer;
								img {
									width: 25px;
									height: 25px;
								}
							}
						}
					}
				}
				.submit-btn {
					width: 300px;
					height: 46px;
					line-height: 46px;
					color: #fff;
					cursor: pointer;
					user-select: none;
					margin: 0 auto 40px;
					text-align: center;
					background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
					border-radius: 34px;
				}
			}
			.user-answer,
			.teacher-appraise {
				padding: 30px 0;
				border-bottom: 1px solid rgba(238, 239, 239, 0.1);
				.h2 {
					color: #ffffff;
					font-weight: 600;
					font-size: 22px;
					margin-bottom: 20px;
				}
				&.rate {
					border: none;
					padding-top: 20px;
				}
				.user-wrap {
					.user {
						font-size: 16px;
						color: #f3f5f6;
						font-weight: bold;
						.avatar {
							width: 42px;
							height: 42px;
							border-radius: 50%;
							margin-right: 20px;
						}
					}
					.time {
						font-size: 13px;
						color: #7c899a;
					}
				}
				.content-wrap {
					margin-top: 30px;
					font-size: 16px;
					color: #f3f5f6;
					.file-img {
						width: 80px;
						height: 80px;
						margin: 20px 20px 0 0;
						border-radius: 6px;
					}
					.modify-work {
						width: fit-content;
						color: #7c899a;
						cursor: pointer;
						padding: 10px 20px 10px 0;
						&:hover {
							color: #15abee;
						}
					}
					.rate-wrap {
						margin-top: 30px;
						color: #8c9094;
						font-size: 13px;
						.el-rate {
							margin-left: 20px;
						}
					}
				}
			}

			.group-container {
				.h1 {
					font-size: 26px;
					color: #f3f5f6;
					margin: 30px 0;
					font-weight: 600;
				}
				// .publish-work-btn {
				// 	width: 300px;
				// 	height: 46px;
				// 	line-height: 46px;
				// 	color: #fff;
				// 	cursor: pointer;
				// 	user-select: none;
				// 	margin: 60px auto;
				// 	text-align: center;
				// 	background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				// 	border-radius: 34px;
				// }
				.group-list {
					.group-user-item {
						border-bottom: 1px solid rgba(238, 239, 239, 0.1);
						padding-top: 20px;
						&:last-child {
							border-bottom: 0px;
						}
						.info {
							font-size: 16px;
							color: #f3f5f6;
							font-weight: bold;
							.time {
								font-size: 13px;
								color: #7c899a;
								font-weight: 400;
							}
						}
						.content {
							margin: 20px 0;
							font-size: 16px;
							color: #f3f5f6;
							padding-left: 30px;
							&.no-submit {
								color: #8c9094;
							}
						}
						.file-img {
							width: 80px;
							height: 80px;
							margin-right: 20px;
							border-radius: 6px;
						}
					}
				}
				.group-nodata {
					color: #ffffff;
					text-align: center;
					padding: 30px 0;
				}
				.overall-evaluation-container {
					background-color: #fff;
					border-radius: 12px;
					padding: 20px 30px;
					margin-top: 60px;
					.title {
						font-size: 22px;
						font-weight: 600;
					}
					.user-wrap {
						margin: 20px 0;
						.user {
							font-size: 16px;
							font-weight: bold;
							.avatar {
								width: 42px;
								height: 42px;
								border-radius: 50%;
								margin-right: 10px;
							}
						}
						.time {
							font-size: 13px;
							color: #7c899a;
						}
					}
					.rate-wrap {
						margin-top: 15px;
						color: #8c9094;
						font-size: 13px;
						.el-rate {
							margin-left: 20px;
						}
					}
				}
			}

			.back {
				width: 300px;
				margin: 30px auto 0;
				height: 54px;
				line-height: 54px;
				background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				border-radius: 27px;
				cursor: pointer;
				user-select: none;
				font-size: 18px;
				text-align: center;
				color: #fff;
			}
		}
	}
}
</style>
